import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import InstagramImage from "./InstagramImage"
import Link from "../common/Link"

export default function Instagram() {
  const data = useStaticQuery(graphql`
    query {
      allInstagramContent(limit: 8) {
        nodes {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          permalink
        }
      }
    }
  `)
  const instagramImages = data.allInstagramContent.nodes
  return (
    <div className="space-y-4">
      <h2 className="">
        <Link
          to="https://instagram.com/typotopia_studio"
          className="hover:underline"
        >
          Typotopia on Instagram
        </Link>
      </h2>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 ">
        {instagramImages.map((image, i) => {
          return <InstagramImage key={i} image={image} />
        })}
      </div>
    </div>
  )
}
