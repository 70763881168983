import React from "react"
import LogoIcon from "../../components/common/LogoIcon"
import LogoIconDirty from "../common/LogoIconDirty"
export default function Ingress() {
  return (
    <div className="py-8 lg:py-16 grid lg:grid-cols-4 items-center lg:px-16">
      <div className="col-span-2 space-y-4 leading-normal text-2xl font-medium">
        <p>
          Typotopia is a letterpress and design studio, a creative work space
          where traditional printing techniques, craftsmanship and contemporary
          design are combined. At our studio at Mitt Möllan in Malmö, Sweden, we
          are printing coasters, business cards, invitations, packaging and art
          prints among others.{" "}
        </p>
        <p>
          Letterpress printed items have a certain feel as the impression makes
          the print a tactile experience and provides a touch of exclusiveness.
          The impression our old Heidelberg Windmill printing presses from the
          1950s and 1960s create is difficult to achieve with digital printing
          techniques.
        </p>
      </div>
      <div className="col-span-1 hidden lg:block"></div>
      <div className="col-span-1">
        <div className=" text-stone-800">
          <LogoIconDirty />
        </div>
      </div>
    </div>
  )
}
