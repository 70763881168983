import React from "react"
import { graphql, useStaticQuery } from "gatsby"
export default function CustomerList() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 426 }) {
        ourCustomers {
          customers {
            customerName
            logo {
              sourceUrl
              publicUrl
            }
          }
        }
      }
    }
  `)
  const customers = data.wpPage.ourCustomers.customers
  return (
    <div className="flex flex-col gap-4">
      <h2>Some of our customers</h2>
      <div className="grid grid-cols-4  lg:grid-cols-7">
        {customers?.map((customer, index) => (
          <div key={index} className="flex justify-center items-center">
            <img
              src={customer.logo.sourceUrl}
              alt={customer.customerName}
              className="w-full"
            />
          </div>
        ))}
      </div>
    </div>
  )
}
