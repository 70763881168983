import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PortfolioGridItem from "./PortfolioGridItem"
import Link from "../common/Link"

export default function PortfolioIndex() {
  const data = useStaticQuery(graphql`
    query {
      allWpPortfolioItem(limit: 12) {
        nodes {
          title
          uri
          slug
          portfolioCategories {
            nodes {
              slug
            }
          }
          portfolioItem {
            imagesPortfolioItem {
              imagePortfolioItem {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1.33
                      layout: CONSTRAINED
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const items = data.allWpPortfolioItem.nodes

  return (
    <div>
      <div className="space-y-4">
        <h2 className=" ">Selected work</h2>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {items.map(item => {
            return <PortfolioGridItem key={item.id} item={item} />
          })}
        </div>
        <div className="lg:text-right">
          <Link to="/work" className="hover:underline font-medium">
            View all
          </Link>
          &nbsp;&rarr;
        </div>
      </div>
    </div>
  )
}
