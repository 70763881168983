import React from "react"
import Instagram from "../components/index/Instagram"
import Link from "../components/common/Link"
import Layout from "../components/layout"
import PortfolioIndex from "../components/portfolio/PortfolioIndex"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Ingress from "../components/index/Ingress"
import IndexGalley from "../components/index/IndexGalley"
import Letterpress from "../components/common/Letterpress"
import CustomerList from "../components/customers/CustomerList"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Typotopia – Letterpress and Design in Malmö" isHome />

    <div className="bg-orange-50">
      <div className="py-4 lg:py-16 ">
        <div className="space-y-4 lg:space-y-16">
          <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 ">
            <PortfolioIndex />
          </div>
          <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 ">
            <Ingress />
          </div>
          <div className="h-screen w-full">
            <div className="overflow-hidden h-full w-full">
              <StaticImage
                src="../images/jens5.jpg"
                objectFit="cover"
                class="h-full w-full"
                quality={100}
              />
            </div>
          </div>
          <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 ">
            <CustomerList />
          </div>
          <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 ">
            <Instagram />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
